"use client";

import HomePageMenu from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/HomePageMenu";
import FooterNew from "./components/Footer-new";
import AdahubGradientBackground from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/AdahubGradientBackground";
import { usePathname } from "next/navigation";
import routes from "@/config/landingPage/routes";
import React from "react";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const noGradient = [routes.contactUs, routes.pricing].includes(pathname);
  const noContactForm = [routes.contactUs, routes.pricing].includes(pathname);
  const solutionsLayout = pathname.includes("/solutions/");
  let _layout;
  if (noGradient) {
    _layout = (
      <div>
        <HomePageMenu />
        {children}
        <FooterNew hasContactForm={!noContactForm} />
      </div>
    );
  } else {
    if (solutionsLayout) {
      _layout = (
        <AdahubGradientBackground color="blue">
          <HomePageMenu />
          {children}
          <FooterNew hasContactForm={!noContactForm} />
        </AdahubGradientBackground>
      );
    } else {
      _layout = (
        <AdahubGradientBackground color="orange">
          <HomePageMenu />
          {children}
          <FooterNew hasContactForm={!noContactForm} />
        </AdahubGradientBackground>
      );
    }
  }

  return <>{_layout}</>;
};

export default Layout;
