"use client";

import Logo from "./logo.svg";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import LandingPageRoutes from "@/config/landingPage/routes";
import Image from "next/image";
import routes from "@/config/landingPage/routes";
import { useRef } from "react";
import { useInView } from "framer-motion";
import { useScrollYPosition } from "react-use-scroll-position";
import useDisclosure from "@/hooks/shared/useDisclosure";

const SolutionsMenu = [
  {
    key: "find-leads",
    url: LandingPageRoutes.solutions.findLeads,
    title: "Tìm kiếm khách hàng",
  },
  {
    key: "customer-support",
    url: LandingPageRoutes.solutions.customerSupport,
    title: "Hỗ trợ khách hàng",
  },
  {
    key: "filter-customers",
    url: LandingPageRoutes.solutions.filterCustomers,
    title: "Lọc khách hàng",
  },
  {
    key: "lead-nurturing",
    url: LandingPageRoutes.solutions.leadNurturing,
    title: "Nuôi dưỡng khách hàng tiềm năng",
  },
  {
    key: "sales-management",
    url: LandingPageRoutes.solutions.salesManagement,
    title: "Quản lý bán hàng",
  },
  {
    key: "telesales",
    url: LandingPageRoutes.solutions.telesales,
    title: "Bán hàng qua điện thoại",
  },
];

const HomePageMenu = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const scrollY = useScrollYPosition();
  const { isOpen, toggle, close: closeMenu } = useDisclosure();

  const startScroll = scrollY > 30 || !isInView;
  return (
    <>
      <Navbar
        ref={ref}
        position="static"
        shouldHideOnScroll={false}
        isMenuOpen={isOpen}
        onMenuOpenChange={toggle}
        style={{ position: "fixed" }}
        className={`overflow-hidden transition-all duration-300 ${!isOpen ? "bg-transparent" : ""} ${scrollY > 50 ? "bg-white" : ""}`}
        height={startScroll ? "3rem" : "4rem"}
        isBordered={isOpen || startScroll}
        isBlurred={false}>
        <NavbarBrand>
          <Link href={LandingPageRoutes.home}>
            <Image src={Logo} alt="adahub logo" />
          </Link>
        </NavbarBrand>
        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                  radius="sm"
                  variant="light">
                  Sản phẩm
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="Menu options"
              className="w-[340px]"
              itemClasses={{
                base: "gap-4",
              }}>
              <DropdownItem
                key="crm"
                description="Quản lý khách hàng tập trung">
                <Link href={routes.products.crm}>CRM</Link>
              </DropdownItem>
              <DropdownItem
                key="callbot"
                description="Giải pháp Callbot AI Auto Call">
                <Link href={routes.products.callbot}>Callbot AI</Link>
              </DropdownItem>
              <DropdownItem key="ulead" description="Tìm kiếm data khách hàng">
                <Link href={routes.products.ulead}>ULead</Link>
              </DropdownItem>
              <DropdownItem
                key="da-kenh"
                description="Quản lý hội thoại đa nền tảng">
                <Link href={routes.products.omnichannel}>Đa kênh</Link>
              </DropdownItem>
              <DropdownItem
                key="sms"
                description="Chiến dịch ZNS, SMS hàng loạt">
                <Link href={routes.products.messages}>Nhắn tin</Link>
              </DropdownItem>
              <DropdownItem key="uflow" description="Quy trình tự động hóa">
                <Link href={routes.products.uflow}>UFlow</Link>
              </DropdownItem>
              <DropdownItem key="tong-dai" description="Tổng đài ảo thông minh">
                <Link href={routes.products.callCenter}>Tổng đài</Link>
              </DropdownItem>
              <DropdownItem
                key="telesales"
                description="Quản lý khách hàng tập trung">
                <Link href={routes.products.telesales}>Telesales</Link>
              </DropdownItem>
              <DropdownItem
                key="email-marketing"
                description="Chiến dịch gửi email hàng loạt">
                <Link href={routes.products.emailMarketing}>
                  Email Marketing
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                  radius="sm"
                  variant="light">
                  Giải pháp
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="ACME features"
              className="w-[340px]"
              itemClasses={{
                base: "gap-4",
              }}>
              {SolutionsMenu?.map((item, index) => (
                <DropdownItem key={item.key}>
                  <Link href={item.url}>{item.title}</Link>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <NavbarItem>
            <Link href={LandingPageRoutes.pricing}>Bảng giá</Link>
          </NavbarItem>
          <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                  radius="sm"
                  variant="light">
                  Tài nguyên
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="ACME features"
              className="w-[340px]"
              itemClasses={{
                base: "gap-4",
              }}>
              <DropdownItem
                key="autoscaling"
                description="ACME scales apps to meet user demand, automagically, based on load.">
                Autoscaling
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <NavbarItem className="hidden lg:flex">
            <Link href={routes.contactUs}>Liên hệ</Link>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent justify="end">
          <NavbarItem>
            <Button as={Link} color="primary" href="#" variant="flat">
              Đăng nhập
            </Button>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      <div style={{ height: "48px" }} />
    </>
  );
};

export default HomePageMenu;
